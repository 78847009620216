import type { IAddCartParams } from '~/api/cart'

export const updateSelectCartQty = (id: number, qty: number) => {
  const userStore = useUserStore()
  const cart = useApi().cart
  const cartStore = useCartStore()
  return cart
    .addSelectCart({
      session_id: userStore.sessionId,
      type: 'SELECT',
      item_id: id,
      quantity: qty
    } as IAddCartParams)
    .then(res => {
      if (res.status.value === 'success') {
        cartStore.setSelectCart(res.data.value ?? {})
        ElNotification({
          message: '已更新購物車'
        })
        return true
      }
      return false
    })
}

export const updateIssueCartQty = (id: number, qty: number) => {
  const userStore = useUserStore()
  const cart = useApi().cart
  const cartStore = useCartStore()
  return cart
    .addISsueCart({
      session_id: userStore.sessionId,
      type: 'ISSUE',
      item_id: id,
      quantity: qty
    } as IAddCartParams)
    .then(res => {
      if (res.status.value === 'success') {
        cartStore.setISsueCart(res.data.value ?? {})
        ElNotification({
          message: '已更新購物車'
        })
        return true
      }
      return false
    })
}

export const delSelectCartItem = (id: number) => {
  const userStore = useUserStore()
  const cart = useApi().cart
  const cartStore = useCartStore()
  return cart
    .delSelectCart({
      item_id: id,
      session_id: userStore.sessionId
    })
    .then(res => {
      if (res.status.value === 'success') {
        cartStore.setSelectCart(res.data.value ?? {})
        ElNotification({
          message: '已更新購物車'
        })
        return true
      }
      return false
    })
}

export const delIssueCartItem = (id: number) => {
  const userStore = useUserStore()
  const cart = useApi().cart
  const cartStore = useCartStore()
  return cart
    .delISsueCart({
      item_id: id,
      session_id: userStore.sessionId
    })
    .then(res => {
      if (res.status.value === 'success') {
        cartStore.setISsueCart(res.data.value ?? {})
        ElNotification({
          message: '已更新購物車'
        })
        return true
      }
      return false
    })
}
